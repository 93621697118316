@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.tab {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -1px;
  text-align: left;
  margin: 0;
  font-weight: 500;
  padding: 0;
  position: relative;
  cursor: pointer;
  padding-right: 7px;

}

.select {
  /* padding-right: 3px; */
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);

  border-bottom: 3px solid var(--marketplaceColor);
  padding-left: 0;
}

.tabs {
  display: flex;
  gap: 45px;
  margin-bottom: 29px;
}

.loginText{
  margin-top: 19px;
  display: block;
}